<template>
  <div>
    <HRetribusi imageLogo="imageLogo" />
    <div v-role="'Wajib Retribusi'">
      <WajibRet />
    </div>

    <!-- begin row -->
    <div class="row" v-role:any="'Administrator|Maintenance|Bendahara|Operasional|Pimpinan'">
      <!-- begin col-3 -->
      <div class="col-xl-3 col-md-6">
        <div
          class="widget widget-stats bg-white text-inverse 
                    card border-1 border-dark mb-3"
        >
          <div class="stats-icon stats-icon-square bg-gradient-blue text-white">
            <i class="ion-ios-analytics"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title text-inverse-lighter">PERIODE</div>
            <div class="stats-number mb-1">
              <div v-if="notifRealisasi.dataPeriode">
                {{ notifRealisasi.dataPeriode.tahunIni }}
              </div>
              <div v-else>
                0
              </div>
            </div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 100%;"></div>
            </div>
            <div class="stats-desc text-inverse-lighter">
              Realisasi penerimaan retribusi
            </div>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-xl-3 col-md-6">
        <!-- <b-card border-variant="dark"> -->
        <div
          class="widget widget-stats bg-blue-transparent-2 text-inverse 
                    card border-1 border-dark mb-3"
        >
          <div class="stats-icon stats-icon-lg">
            <i class="ion-md-stats"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title text-inverse-lighter">
              <div v-if="notifRealisasi.dataPeriode">
                <!-- REALISASI  -->
                {{
                  notifRealisasi.dataPeriode.bulanSebelumnyaNama.toUpperCase()
                }}
                {{ notifRealisasi.dataPeriode.tahunIni }}
              </div>
              <div v-else>
                ...
              </div>
            </div>
            <div class="stats-number text-right mb-1">
              <div v-if="notifRealisasi.dataBulanSebelumnya">
                {{
                  notifRealisasi.dataBulanSebelumnya.total_nilai
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </div>
              <div v-else>
                0
              </div>
            </div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 100%;"></div>
            </div>
            <div class="stats-desc text-inverse-lighter">
              <div v-if="notifRealisasi.dataBulanSebelumnya">
                {{
                  notifRealisasi.dataBulanSebelumnya.total_qty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
                Ketetapan
              </div>
              <div v-else>
                0 Ketetapan
              </div>
            </div>
          </div>
        </div>
        <!-- </b-card> -->
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-xl-3 col-md-6">
        <div
          class="widget widget-stats bg-blue-transparent-2 text-inverse 
                    card border-1 border-dark mb-3"
        >
          <div class="stats-icon stats-icon-lg">
            <i class="ion-md-stats"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title text-inverse-lighter">
              <div v-if="notifRealisasi.dataPeriode">
                <!-- REALISASI  -->
                {{ notifRealisasi.dataPeriode.bulanIniNama.toUpperCase() }}
                {{ notifRealisasi.dataPeriode.tahunIni }}
              </div>
              <div v-else>
                ...
              </div>
            </div>
            <div class="stats-number text-right mb-1">
              <div v-if="notifRealisasi.dataBulanIni">
                {{
                  notifRealisasi.dataBulanIni.total_nilai
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </div>
              <div v-else>
                0
              </div>
            </div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 100%;"></div>
            </div>
            <div class="stats-desc text-inverse-lighter">
              <div v-if="notifRealisasi.dataBulanIni">
                {{
                  notifRealisasi.dataBulanIni.total_qty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
                Ketetapan
              </div>
              <div v-else>
                0 Ketetapan
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
      <!-- begin col-3 -->
      <div class="col-xl-3 col-md-6">
        <div
          class="widget widget-stats bg-blue-transparent-2 text-inverse 
                    card border-1 border-dark mb-3"
        >
          <div class="stats-icon stats-icon-lg">
            <i class="ion-md-stats"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title text-inverse-lighter">
              <div v-if="notifRealisasi.dataPeriode">
                <!-- REALISASI  -->
                {{ notifRealisasi.dataPeriode.tahunIni }}
              </div>
              <div v-else>
                ...
              </div>
            </div>
            <div class="stats-number text-right mb-1">
              <div v-if="notifRealisasi.dataTahunIni">
                {{
                  notifRealisasi.dataTahunIni.total_nilai
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </div>
              <div v-else>
                0
              </div>
            </div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 100%;"></div>
            </div>
            <div class="stats-desc text-inverse-lighter">
              <div v-if="notifRealisasi.dataTahunIni">
                {{
                  notifRealisasi.dataTahunIni.total_qty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
                Ketetapan
              </div>
              <div v-else>
                0 Ketetapan
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col-3 -->
    </div>
    <!-- end row -->
    <!-- Tabel dan data grafik realisasi dan target -->
    <b-row class="align-self-stretch mb-3" v-role:any="'Administrator|Maintenance|Bendahara|Operasional|Pimpinan'">
      <b-col class="mb-2" sm="12" :lg="['Administrator', 'Maintenance'].includes(profil.roles[0].name) ? 12 : 8">
        <panel
          :title="`DATA REALISASI PENERIMAAN TAHUN ${moment().format('YYYY')}`"
          class="panel panel-success h-100"
        >
          <div class="text-center" v-if="data">
            <h4 class="mb-1 font-weight-bold">
              {{ data.unit_pd.perangkat_pd.nama.toUpperCase() }}
            </h4>
            <h5 class="mb-1">
              {{ data.unit_pd.nama.toUpperCase() }}
            </h5>
            <h5 class="mb-1">
            TABEL REALISASI PENERIMAAN {{ moment().year() }}
            </h5>
          </div>
          <b-table-simple
            hover
            small
            bordered
            responsive
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th colspan="5" class="text-center">
                  APBD TA. {{ moment().format('YYYY') }}
                </b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center">
                  Kode Rek.
                </b-th>
                <b-th class="text-center" width="300px">
                  Uraian
                </b-th>
                <b-th class="text-center">
                  Target APBD TA. {{ moment().format('YYYY') }}
                </b-th>
                <b-th class="text-center">
                  {{ `Sampai dengan ${moment().locale('id').subtract(1, 'months').endOf('month').format('DD MMMM YYYY')}` }}
                </b-th>
                <b-th class="text-center">
                  {{ `${moment().locale('id').format('MMMM')}` }}
                </b-th>
                <b-th class="text-center">
                  Jumlah
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="socketData.tabelLoading">
              <b-tr>
                <b-td colspan="6" class="text-center font-weight-bold">
                  <b-spinner class="align-middle"></b-spinner>
                  <span>Loading...</span>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr v-if="!socketData.realisasi">
                <b-td colspan="6" class="text-center font-weight-bold">
                  No. result found
                </b-td>
              </b-tr>
              <template v-for="(item) in socketData.realisasi" v-else>
                <b-tr :key="item.id" class="font-weight-bold">
                  <b-td>
                    {{ item.kode_pd }}
                  </b-td>
                  <b-td>
                    {{ item.nama }}
                  </b-td>
                  <b-td class="text-right">
                    {{ item.total.target == 0 ? 0 : item.total.target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      100&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ item.total.sd_bulan_sebelumnya == 0 ? 0 : item.total.sd_bulan_sebelumnya.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      {{ item.total.sd_bulan_sebelumnya_persen }}&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ item.total.bulan_ini == 0 ? 0 : item.total.bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      {{ item.total.bulan_ini_persen }}&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ item.total.sd_bulan_ini == 0 ? 0 : item.total.sd_bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}
                    <div>
                      {{ item.total.sd_bulan_ini_persen }}&percnt;
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-for="(target) in item.target" :key="target.id">
                  <b-td>
                  {{ target.ref_rek_6.kode }}
                  </b-td>
                  <b-td>
                    {{ target.ref_rek_6.nama }}
                  </b-td>
                  <b-td class="text-right">
                    {{ target.apbd == 0 ? 0 : target.apbd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      100&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ target.realisasi.sd_bulan_sebelumnya === null ? 0 : target.realisasi.sd_bulan_sebelumnya.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      {{ target.realisasi.sd_bulan_sebelumnya_persen }}&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ target.realisasi.bulan_ini === null ? 0 : target.realisasi.bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      {{ target.realisasi.bulan_ini_persen }}&percnt;
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    {{ target.realisasi.sd_bulan_ini === 0 ? 0 : target.realisasi.sd_bulan_ini.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    <div>
                      {{ target.realisasi.sd_bulan_ini_persen }}&percnt;
                    </div>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <div class="d-flex justify-content-start">
            <b-button variant="success" @click="() => $router.push({ name: 'LaporanRealisasi' })">
              <i class="fa fa-file-alt"></i> Lihat Laporan Realisasi Penerimaan
            </b-button>
          </div>
          <!-- Chart JS -->
          <!-- <div class="my-5 text-center"> -->
            <!-- <h4>
            GRAFIK REALISASI PENERIMAAN {{ moment().year() }}
            </h4>
            <bar-chart :data="chartData" :options="chartOptions" height="150px" />
          </div> -->
          <!-- end of Chart JS -->
        </panel>
      </b-col>
      <b-col class="mb-2" sm="12" :lg="['Administrator', 'Maintenance'].includes(profil.roles[0].name) ? 12 : 4">
        <panel 
          class="panel panel-success h-100"
          title="Grafik Realisasi Penerimaan"
        >
          <!-- Chart JS -->
          <div class="text-center" style="position: relative; height: 100%;">
            <h4>
            GRAFIK REALISASI PENERIMAAN {{ moment().year() }}
            </h4>
            <bar-chart :data="chartData" :options="chartOptions" v-if="!socketData.tabelLoading" />
            <div v-else style="height: 400px;display: flex;align-items: center;justify-content: center;">
              <div>
                <b-spinner label="Spinning"></b-spinner>
                <div class="font-weight-bold">
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <!-- end of Chart JS -->
        </panel>
      </b-col>
    </b-row>
    <!-- end of tabel dan data grafik -->
    <!-- Table Top 10 of realisasi penerimaan --->
    <panel
      class="panel panel-success"
      title="Top 10 Realisasi Penerimaan"
      v-role:any="'Administrator|Maintenance|Bendahara|Operasional|Pimpinan'"
    >
    <div class="text-center" v-if="data">
        <h4 class="mb-1 font-weight-bold">
          {{ data.unit_pd.perangkat_pd.nama.toUpperCase() }}
        </h4>
        <h5 class="mb-1">
          {{ data.unit_pd.nama.toUpperCase() }}
        </h5>
        <h5 class="mb-1">
         TOP 10 REALISASI PENERIMAAN {{ moment().year() }}
        </h5>
      </div>
      <b-table
        show-empty
        small
        bordered
        id="masTable"
        ref="masTable"
        head-variant="light"
        hover
        :items="socketData.itemFavorit"
        :fields="item_favorit_fields"
        :busy.sync="socketData.isLoading"
      >
         <!-- 
                lebar kolom
          -------------------------------------------------- -->
          <template v-slot:table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                      width: 
                          field.key === 'aktif' ? '65px' : 
                          field.key === 'actions' ? '105px' : ''
                  }"
              />
          </template>
          <!-- 
          nomor baris
          -------------------------------------------------- -->
          <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
              </div>
          </template>
          <template #cell(index)="data">
              {{ data.index + 1 }}
          </template>
          <template #cell(perangkat_pd)="data">
            {{ data.item.perangkat_pd.nama }}
          </template>
          <template #cell(qty)="data">
            {{ Math.round(data.item.qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </template>
          <template #cell(ret_parameter)="data">
            <div v-html="data.item.ret_parameter"></div>
          </template>
          <template #cell(ret_rincian)="data">
            <div v-html="data.item.ret_rincian">
            </div>
          </template>
          <template #cell(total)="data">
            {{ data.item.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </template>
      </b-table>
      <div class="d-flex justify-content-start">
        <b-button variant="success" @click="() => $router.push({ name: 'LaporanRealisasiItem' })">
          <i class="fa fa-file-alt"></i> Lihat Laporan Realisasi Penerimaan Per Item
        </b-button>
      </div>
    </panel>
    <!-- end of table-->
    <panel
      class="panel panel-success"
      v-role:any="'Administrator|Maintenance|Bendahara|Operasional|Pimpinan'"
    >
      <div>
        <div v-if="profil.pegawai_id">
          <b-table-simple outlined borderless small responsive>
            <b-tbody>
              <b-tr>
                <b-td width="15%" class="text-center align-middle py-2">
                  <img :src="imageLogo" alt="logo" width="70" />
                </b-td>
                <b-td width="70%" class="text-center align-middle">
                  <h4 class="font-weight-bold m-0">
                    PEMERINTAH PROVINSI SUMATERA UTARA
                  </h4>
                  <h5 v-if="data" class="font-weight-bold m-0">
                    {{ data.unit_pd.perangkat_pd.nama.toUpperCase() }}
                  </h5>
                  <h5 v-if="data" class="mb-1">
                    {{ data.unit_pd.nama.toUpperCase() }}
                  </h5>
                  <div
                    v-if="data"
                    class="font-weight-bold text-muted"
                    style="font-size: 11px"
                  >
                    <i class="fas fa-map-marker-alt mr-1"></i>
                    {{ data.unit_pd.alamat }}
                  </div>
                </b-td>
                <b-td width="15%"> </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-table-simple outlined bordered small responsive class="mb-0">
            <b-tbody>
              <b-tr>
                <b-td class="p-0">
                  <b-table-simple bordered small responsive class="m-0">
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-center align-middle">
                          <h5 class="font-weight-bold m-0">
                            PROFIL SKPD,UPTD DAN IDENTITAS PENGGUNA E-RETRIBUSI
                          </h5>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <!--  -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="p-0">
                  <b-table-simple bordered small responsive class="m-0">
                    <b-tbody>
                      <b-tr>
                        <b-td colspan="2" class="font-weight-bold">
                          <u>IDENTITAS SKPD &amp; UPT</u>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Nama SKPD
                        </b-td>
                        <b-td class="font-weight-bold">
                          <div v-if="data">
                            {{ data.unit_pd.perangkat_pd.nama.toUpperCase() }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Nama UPT
                        </b-td>
                        <b-td class="font-weight-bold">
                          <div v-if="data">
                            {{ data.unit_pd.nama.toUpperCase() }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Alamat
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.unit_pd.alamat }}
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <!--  -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="p-0">
                  <b-table-simple bordered small responsive class="m-0">
                    <b-tbody>
                      <b-tr>
                        <b-td colspan="2" class="font-weight-bold">
                          <u>IDENTITAS PENGGUNA E-RETRIBUSI</u>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          NIK
                        </b-td>
                        <b-td class="font-weight-bold">
                          <div v-if="data">
                            {{ data.nik }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          NIP
                        </b-td>
                        <b-td class="font-weight-bold">
                          <div v-if="data">
                            {{ data.nip }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Nama
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.nama }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Tempat &amp; Tanggal Lahir
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.tmp_lahir }},
                            {{ data.tgl_lahir }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Jenis Kelamin
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.jenis_kelamin }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Nomor Telepon
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.nomp }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Email
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.email }}
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td width="15%" class="">
                          Alamat
                        </b-td>
                        <b-td class="">
                          <div v-if="data">
                            {{ data.alamat }}
                            <div v-if="data.wilayah_kelurahan">
                              Kel. {{ data.wilayah_kelurahan.nama }}<br />
                              Kec.
                              {{ data.wilayah_kelurahan.wilayah_kecamatan.nama
                              }}<br />
                              {{
                                data.wilayah_kelurahan.wilayah_kecamatan
                                  .wilayah_kabkota.nama
                              }}<br />
                              {{
                                data.wilayah_kelurahan.wilayah_kecamatan
                                  .wilayah_kabkota.wilayah_provinsi.nama
                              }}<br />
                              {{ data.kodepos }}
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <!--  -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="p-0">
                  <b-table-simple bordered small responsive class="m-0">
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          <strong>Nb: </strong>
                          Hubungi admin Aplikasi E-Retribusi apabila terdapat
                          kesalahan data.
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else>
          <b-card>
            <h1>Sistem E-Retribusi</h1>
          </b-card>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
/* eslint-disable */
import Logo from "@/assets/images/logoSumut.png";
import PageOptions from "../config/PageOptions.vue";
import HRetribusi from "@/components/npwr/HRetribusi.vue";
import WajibRet from "@/components/npwr/wajib_retribusi/FormWajibRet.vue";
import axios from "axios";
import hapusStorage from "@/helper/hapusLocalStore";
import BarChart from "@/components/vue-chartjs/BarChart.js";
import dashboardData from "./dashboard.json";
import moment from "moment";

export default {
  mixins: [hapusStorage],
  data() {
    return {
      moment,
      dataDashboard: dashboardData,
      imageLogo: Logo,
      npwpd_id: JSON.parse(localStorage.getItem("user")).npwpd_id,
      profil: JSON.parse(localStorage.getItem("user")),
      pegawai: JSON.parse(localStorage.getItem("pegawai")),
      role: JSON.parse(localStorage.getItem('roles'))[0],
      data: null,

      notifRealisasi: [],
      // notifRealisasi: {
      //     dataPenetapan: {
      //         total_qty: 0,
      //         total_pokok: 0,
      //         total_denda: 0,
      //         total_nilai: 0
      //     }
      // },
      fields: [
        {
          key: "kode_pd",
          label: "Kode Rek.",
          thClass: "text-center"
        },
        {
          key: "nama",
          label: "Uraian",
          thClass: "text-center"
        },
        {
          key: "total.target",
          label: "Target APBD TA. 2023",
          thClass: "text-center",
          tdClass: "text-right"
        },
        {
          key: "total.sd_bulan_sebelumnya",
          label: `Sampai dengan ${moment().locale('id').subtract(1, 'months').endOf('month').format('DD MMMM YYYY')}`,
          thClass: "text-center",
          tdClass: "text-right"
        },
        {
          key: "total.bulan_ini",
          label: `${moment().locale('id').format('MMMM')}`,
          thClass: "text-center",
          tdClass: "text-right"
        },
        {
          key: "total.sd_bulan_ini",
          label: "Jumlah",
          thClass: "text-center",
          tdClass: "text-right"
        }
      ],
      item_favorit_fields: [
        {
            key: "index",
            label: "No.",
            thClass: "text-center",
            tdClass: "text-center"
        },
        {
          key: "perangkat_pd",
          label: "SKPD",
          thClass: "text-center",
        },
        {
          key: "ret_rincian",
          label: "RINCIAN",
          thClass: "text-center",
        },
        {
          key: "qty",
          label: "Kuantitas",
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          key: "ret_parameter",
          label: "Satuan",
          thClass: "text-center",
        },
        {
          key: "total",
          label: "Total Penerimaan \n (IDR)",
          tdClass: "text-right",
          thClass: "text-center",
        }
      ],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Target",
            borderColor: "rgba(237, 45, 71, 0.8)",
            type: "line",
            fill: false,
            data: []
          },
          {
            label: "Realisasi",
            backgroundColor: "rgba(8, 94, 68, 0.8)",
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    fontSize: 10,
                    callback: function(value) {
                      return value.key
                    }
                }
            }],
            yAxes: [{
                ticks: {
                  callback: function(value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                    return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                },
                beforeTitle: function (tooltipItem) {
                  return tooltipItem[0].xLabel.head
                },
                title: function(tooltipItem) {
                  return tooltipItem[0].xLabel.key
                },
                afterTitle: function(tooltipItem) {
                  return tooltipItem[0].xLabel.value
                }
            }
        }
      },
      socketData: {
        itemFavorit: [],
        realisasi: null,
        isLoading: false,
        tabelLoading: false
      },
      queryParams: {
        perangkat_pd_id: null,
        unit_id: null,
        tahun: null,
        bulan: null,
        sortBy: 'total',
        item_favorit: true
      }
    };
  },
  components: {
    HRetribusi,
    WajibRet,
    BarChart
  },
  created() {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    if (this.pegawai) {
      // get broadcast data realisasi ...
      this.listenForRealisasi(this.role, this.pegawai.unit_pd.perangkat_pd_id);
      // // get broadcast data item favorit
      this.listenItemFavorit(this.role, this.pegawai.unit_pd.perangkat_pd_id)
      // // get data realisasi ...
      this.getDataRealisasi(this.pegawai.unit_pd.perangkat_pd_id);
      // // broadcast realisasi penerimaan
      this.listenRealiasasi(this.role, this.pegawai.unit_pd.perangkat_pd_id)
    }
  },
  mounted() {
    if (this.profil.pegawai_id) {
      this.getPegawai(this.profil.pegawai_id);
    }
    // get data realisasi
    this.getDataRealisasi()
    // get data realisasi per item
    this.getRealisasiPerItem()
    // get data realisasi penerimaan
    this.getRealisasiPenerimaan({
      bulan: moment().format('M'),
      tahun: moment().format('YYYY'),
      perangkat_pd_id: this.queryParams.perangkat_pd_id
    })
  },
  methods: {
    // socket role 
    switchListenerRole: function (role, channel, perangkat_pd_id) {
      let customChannel = channel
      if (!['Administrator', 'Maintenance'].includes(role)) {
        customChannel = `${channel}.${perangkat_pd_id}`
      }
      return customChannel
    },
    // get data realisasi ...
    getDataRealisasi() {
      axios
        .get("/api/transaksi/spt/realisasi")
        .then(response => {
          this.notifRealisasi = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    // get broadcast data realisasi ...
    listenForRealisasi(role, perangkat_pd_id) {
      const channel = this.switchListenerRole(role, 'realisasi', perangkat_pd_id)
      window.Echo.private(channel).listen("RealisasiEvent", response => {
        this.notifRealisasi = response;
        // this.notification.push(response);
        // console.log(e.message.replace('{name}', e.name));
        // this.notification.push(e.message.replace('{name}', e.name))
      });
    },
    // get realisasi per item
    getRealisasiPerItem: async function (params={}) {
      try {
        this.socketData.isLoading = true
        const response = await axios.get('/api/transaksi/spt/realisasi/item-favorit', { params })
        if (response) {
          this.socketData.itemFavorit = response.data.data
          this.socketData.isLoading = false
        }
      } catch (error) {
        this.socketData.isLoading = false
        console.log(error)
      }
    },
    // input data to chart
    setDataToChart: function (array) {
        array.map(item => {
          item.target.map(value => {
            this.chartData.labels.push({
              head: item.nama,
              key: value.ref_rek_6.kode,
              value: value.ref_rek_6.nama
            })
            this.chartData.datasets[0].data.push(value.apbd)
            this.chartData.datasets[1].data.push(value.realisasi.sd_bulan_ini)
          })
        })
    },
    // get realisasi peneriamaan
    getRealisasiPenerimaan: async function (params) {
      try {
        this.socketData.tabelLoading = true
        const response = await axios.get('/api/laporan/realisasi/penerimaan', {
          params
        })
        if (response) {
          this.socketData.realisasi = response.data.data.realisasi
          this.setDataToChart(this.socketData.realisasi)
          this.socketData.tabelLoading = false
        }
      } catch (error) {
        console.log(error)
        this.socketData.tabelLoading = false
      }
    },
    // get data pegawai ...
    getPegawai(id) {
      axios
        .get("/api/data-induk/kepegawaian/pegawai/" + id)
        .then(response => {
          this.data = response.data.data;
          this.queryParams = {
            perangkat_pd_id: this.data.perangkat_pd_id,
            item_favorit: true
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // socket listener for realisasi penerimaan per item
    listenItemFavorit: function (role, perangkat_pd_id) {
      const channel = this.switchListenerRole(role, 'item-favorit', perangkat_pd_id)
      window.Echo.private(channel).listen('ItemFavoritEvent', response => {
        this.socketData.itemFavorit = response.data
      })
    },
    // socket listener for realisasi penerimaan
    listenRealiasasi: function (role, perangkat_pd_id) {
      const channel = this.switchListenerRole(role, 'realisasi-penerimaan', perangkat_pd_id)
      window.Echo.private(channel).listen('RealisasiPenerimaanEvent', response => {
        this.socketData.realisasi = response.data.realisasi
        this.setDataToChart(this.socketData.realisasi)
      })
    }
  }
};
</script>
